import React, { Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../_helpers';
import MainComponent from '../_components/MainComponent';
import { PrivateRoute } from '../_components/PrivateRoute';
import { getApplicationStatus } from '../_actions';
import Transactions_history from '../pages/PaymentAndTransactions/components/Transactions_history/Transactions_history';
import ScrollToTop from '../_hook/scrollToTop';
import QuickReviewModal from '../_modals/QuickReviewModal/QuickReviewModal';
import QRModal from '../_modals/QRModal/QRModal';
import { localeSelector } from '../_selectors/main.selector';
import Loader from '../_components/Loader/Loader';

import '../assets/_components.scss';

const About = React.lazy(() => import('../pages/About/About'));
const CouponPage = React.lazy(() => import('../pages/CouponPage/CouponPage'));
const Categories = React.lazy(() => import('../pages/Categories/Categories'));
const ParentCategories = React.lazy(() => import('../pages/Categories/ParentCategories'));
const Category = React.lazy(() => import('../pages/Category/CategoryPage'));
const ParentCategory = React.lazy(() => import('../pages/Category/ParentCategory'));
const Company = React.lazy(() => import('../pages/Company/Company'));
const CompanyRegister = React.lazy(() => import('../pages/CompanyRegister/CompanyRegister'));
const FavouriteCategories = React.lazy(() => import('../pages/FavouriteCategories/FavouriteCategories'));
const Contact = React.lazy(() => import('../pages/Contact/Contact'));
const Help = React.lazy(() => import('../pages/Help/Help'));
const Home = React.lazy(() => import('../pages/Home/Home'));
const NearBy = React.lazy(() => import('../pages/NearBy'));
const Privacy = React.lazy(() => import('../pages/Privacy/Privacy'));
const AccessibilityStatement = React.lazy(() => import('../pages/AccessibilityStatement/AccessibilityStatement'));
const NotFound = React.lazy(() => import('../pages/NotFound/NotFound'));
const Profile = React.lazy(() => import('../pages/Profile/Profile'));
const Search = React.lazy(() => import('../pages/Search/Search'));
const Notifications = React.lazy(() => import('../pages/Notifications/Notifications'));
const QrApplication = React.lazy(() => import('../pages/QrApplication/QrApplication'));
const SavedCoupons = React.lazy(() => import('../pages/SavedCoupons/SavedCoupons'));
const Settings = React.lazy(() => import('../pages/Settings/Settings'));
const Terms = React.lazy(() => import('../pages/Terms/Terms'));
const SuitableForMe = React.lazy(() => import('../pages/SuitableFomMe/SuitableForMe'));
const CouponsPage = React.lazy(() => import('../pages/Coupons/CouponsPage'));
const ShopByProviders = React.lazy(() => import('../pages/ShopByProviders/ShopByProviders'));
const RemoveAccount = React.lazy(() => import('../pages/Profile/RemoveAccount/RemoveAccount'));
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PublishModal from './PublishModal';
// import cookies from '../_helpers/cookies';
import { notifyAPKAboutToken } from '../_services';
import { useCheckSupportedVersion } from '../_hook/useCheckSupportedVersion';
import UpdateMobileModal from './UpdateMobileModal';
import { useGetMobileBuildVersion } from '../_hook/useGetMobileBuildVersion';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useCheckSupportedVersion();
  useGetMobileBuildVersion();
  const locale = useSelector(localeSelector);
  const status = useSelector((state) => state.applicationsReducer.status);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (status === 'mobile') {
  //     hideAccessibilityOnMobileApp();
  //   }
  // }, [status]);

  // const hideAccessibilityOnMobileApp = () => {
  //   setTimeout(() => {
  //     const userway = document.querySelector('#userwayAccessibilityIcon');
  //     if (userway) {
  //       userway.style.display = 'none';
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    // window.addEventListener(
    //   'load',
    //   () => {
    //     window.scrollTo(0, 0);

    //     new Accessibility({ textPixelMode: true }, locale);
    //   },
    //   false,
    // );
    // const token = cookies.utils.getAuth();
    // if (token) {
    //   window.ReactNativeWebView.postMessage('loginToken:' + token);
    // }

    notifyAPKAboutToken();
    dispatch(getApplicationStatus());
  }, []);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <div>
        <QueryClientProvider client={queryClient}>
          <MainComponent>
            {/* <PublishModal /> */}
            <UpdateMobileModal />
            <ScrollToTop />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route path="/:lang/coupon/:provider/:name/:id/:status?" component={CouponPage} />
                <Route path="/:lang/search/:value" component={Search} />
                <Route path="/:lang/categories" component={Categories} />
                <Route path="/:lang/main-categories" component={ParentCategories} />
                <Route path="/:lang/main-category/:name/:id" component={ParentCategory} />
                <Route path="/:lang/company/:provider/:idCompany" component={Company} />
                <Route path="/:lang/category/:name/:id" component={Category} />
                <Route path="/:lang/register_as_manufacturer" component={CompanyRegister} />
                <Route path="/:lang/about" component={About} />
                <Route path="/:lang/help" component={Help} />
                <Route path="/:lang/contact" component={Contact} />
                <Route path="/:lang/terms_of_use" component={Terms} />
                <Route path="/:lang/privacy" component={Privacy} />
                <Route path="/:lang/coupons" component={CouponsPage} />
                <Route path="/:lang/shop-by-providers" component={ShopByProviders} />
                <Route path="/:lang/accessibility_statement" component={AccessibilityStatement} />
                <Route path="/:lang/remove-account" component={RemoveAccount} />
                <PrivateRoute path="/:lang/profile" component={Profile} />
                <PrivateRoute path="/:lang/transactions-history" component={Transactions_history} />
                <PrivateRoute path="/:lang/settings" component={Settings} />
                <PrivateRoute path="/:lang/saved-coupons" component={SavedCoupons} />

                <PrivateRoute path="/:lang/preffered_categories" component={FavouriteCategories} />

                <PrivateRoute path="/:lang/notifications" component={Notifications} />

                <PrivateRoute path="/:lang/suitable_for_me" component={SuitableForMe} />

                <PrivateRoute path="/:lang/nearby" component={NearBy} />
                <Route path="/:lang/qr" component={QrApplication} />

                <Route path="/:lang/" exact component={Home} />
                <Route path="/" exact component={Home} />

                <Route component={NotFound} />
              </Switch>
            </Suspense>
            <QuickReviewModal />
            <QRModal />
          </MainComponent>
        </QueryClientProvider>
      </div>
    </PersistGate>
  );
}

export default App;
