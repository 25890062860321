import React, { useEffect } from 'react';
import './UpdateMobileModal.scss';
import xSvg from '../assets/images/publish-modal/ic_baseline-close.svg';
import { useLanguage } from '../_hook/useLanguage';
import { TRANSLATIONS } from '../_constants';
import { Link } from 'react-router-dom';
import { getMobileOperatingSystem } from '../_helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import android from '../assets/images/google-play.png';
import ios from '../assets/images/app-store.png';
import { setUpdateMobileAppModal } from '../_actions';
import { useTimeout } from '../_hook/useTimeout';


const OS = getMobileOperatingSystem();
const IOS_LINK = `https://apps.apple.com/us/app/chipper/id1538769004`;
const ANDROID_LINK = `https://play.google.com/store/apps/details?id=com.chipper`;

function UpdateMobileModal() {
  const showUpdateModal = useSelector((state) => state.modalReducer?.updateMobileApp);
  const mobileVersions = useSelector((state) => state.applicationsReducer?.mobileVersions);
  const dispatch = useDispatch();

  const appEnv = useSelector((state) => state.applicationsReducer?.status);
  const isMobile = appEnv === 'mobile';
  const { locale } = useLanguage();

  useTimeout(() => {
    if (!isMobile) return;

    const localeStorageMobileBuildVersion = localStorage.getItem('mobileBuildVersion');
    // const localeStorageOS = localStorage.getItem('os');

    const { CURRENT_ANDROID_BUILD_VERSION, CURRENT_APPLE_BUILD_VERSION } = mobileVersions;
    if (!CURRENT_ANDROID_BUILD_VERSION || !CURRENT_APPLE_BUILD_VERSION) return;

    const serverAndroidVersion = Number(CURRENT_ANDROID_BUILD_VERSION);
    const serverAppleVersion = Number(CURRENT_APPLE_BUILD_VERSION);
    const localStorageVersion = localeStorageMobileBuildVersion ? Number(localeStorageMobileBuildVersion) : 0;

    if (OS === 'iOS' && serverAppleVersion > localStorageVersion) {
      dispatch(setUpdateMobileAppModal(true));
    } else if (OS === 'Android' && serverAndroidVersion > localStorageVersion) {
      dispatch(setUpdateMobileAppModal(true));
    }
  }, 9000);

  const handleModalClose = () => {
    dispatch(setUpdateMobileAppModal(false));
  };

  return (
    <div>
      {showUpdateModal && isMobile && (
        <div className="update-modal-wrapper">
          <div className="update-modal">
            <button className="close-button" onClick={handleModalClose}>
              <img alt="x" src={xSvg} />
            </button>
            <div className="content">
              <img src={OS === 'iOS' ? ios : android} className={OS === 'iOS' ? 'ios frame' : 'frame'} alt="frame" />
              <h2 dangerouslySetInnerHTML={{ __html: TRANSLATIONS[locale]['updateModalText'] }}></h2>
            </div>
            <a
              onClick={handleModalClose}
              className="link"
              href={OS === 'Android' ? ANDROID_LINK : IOS_LINK}

            >
              {TRANSLATIONS[locale]['update']}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateMobileModal;
